<!-- Page Content -->
<!--
Section on addiction, substance, weight loss therapy, trans-gender
Create section Lajeans will also be providing Covid vaccinations
 <h1 class="font-weight-medium">Find your health+happiness and learn to put it first</h1>
-->

<div class="row">
    <div class="col-12">
        <div class="card bg-dark border-0 shadow card-height-intro">
            <img class="card-img card-img-cover" src="../../../assets/img/beach_freedom.jpg" alt="Card image">
            <div class="card-img-overlay">

                <p class="card-text card-title-text-cover">Please be advised the office is closed as a result of a family emergency. </p>
                <p class="card-text card-title-text-cover">If you need assistance, please contact your primary care provider, </p>
                <p class="card-text card-title-text-cover">call 911 or go to your nearest emergency room</p>
                <br />
                <p class="card-text card-title-text-cover_2"></p>
                <p class="text-center">
                   <!-- <a href="#" class="btn btn-primary">Get Started</a> -->
                </p>
            </div>

        </div>
        <br />
        <div class="row">
            <div class="col"><hr></div>
            <div class="col-auto text-divider">Find your health+happiness and learn to put it first</div>
            <div class="col"><hr></div>
        </div>
        <br />
        <div class="container">
            <fieldset class="field_set_color">
                <legend class="legend_color">Featured Articles</legend>
                <div class="row">
                    
                    <div class="col-sm-3">
                        <a [routerLink]="['/anxiety']">
                        <div class="card  border-0 bg-dark text-white text-center clear-card">
                            <img class="card-img" src="../../../assets/img/substance.jpg" alt="Card image">
                            <div class="card-img-overlay">
                                <h5 class="card-title"></h5>
                                <p class="card-text card-text-size">Anxiety/Depression</p>

                            </div>
                        </div>
                    </a>
                    </div>
                    <div class="col-sm-3">
                        <a [routerLink]="['/addiction']">
                        <div class="card  border-0 bg-dark text-white text-center clear-card">
                            <img class="card-img" src="../../../assets/img/addiction_2.jpg" alt="Card image">
                            <div class="card-img-overlay">
                                <h5 class="card-title"></h5>
                                <p class="card-text card-text-size"><p class="card-text card-text-size">Addiction</p>

                            </div>
                        </div>
                    </a>
                    </div>
                    <div class="col-sm-3">
                        <a [routerLink]="['/transgender']">
                        <div class="card  border-0 bg-dark text-white text-center clear-card">
                            <img class="card-img" src="../../../assets/img/trans_gender.jpg" alt="Card image">
                            <div class="card-img-overlay">
                                <h5 class="card-title"></h5>
                                <p class="card-text card-text-size">Trans Gender</p>

                            </div>
                        </div>
                    </a>
                    </div>
                </div>
            </fieldset>
            <br /> <br />
            <!--<div class="row" style="text-align:center;">
                <div class="col-12">
                    <img src="../../../assets/img/Get Well Fair Flyer 10.08.24 KS.png" style="max-width: 100%; height:450px">
                </div>
            </div>-->
            
            <fieldset class="field_set_color">
               
                <div class="row">
                    <div class="col-sm-6">
                        <div class="text-left">General Office Hours/Days</div>
                        <br />
                        <div class="card  border-0 bg-light text-black text-left clear-card">
                            <table>
                                <tr><th>Sunday</th><td>Closed</td></tr>
                                <tr><th>Monday</th><td>9am - 5pm (Lunch 12:00 - 1:00)</td></tr>
                                <tr><th>Tuesday</th><td>9am - 5pm (Lunch 12:00 - 1:00)</td></tr>
                                <tr><th>Wednesday</th><td>9am - 5pm (Lunch 12:00 - 1:00)</td></tr>
                                <tr><th>Thursday</th><td>9am - 5pm (Lunch 12:00 - 1:00)</td></tr>
                                <tr><th>Friday</th><td>9am - 5pm (Lunch 12:00 - 1:00)</td></tr>
                                <tr><th>Saturday</th>Closed<td></td></tr>
                                <tr><td>Staff is unavailable to answer phones after 4:30pm</td></tr>
                                
                                </table>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card  border-0 bg-light text-black clear-card">


                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card  border-0 bg-light text-black clear-card">


                        </div>
                    </div>
                    
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-3">
                        
                        <div class="card border-0 bg-light text-black text-left clear-card">
                            <address>
                                Lajeans Mental Health Boutique<br />
                                3094 W Market St Suite 190<br />
                                Fairlawn OH 44333<br />
                                <br />
                                Email Us At:<br />
                                <a href="mailto:lajeansmhb@outlook.com">lajeansmhb@outlook.com</a><br /><br />
                                Call Us At:<br />
                                <a href="tel:330-595-9929">330-595-9929</a><br />
                                Fax Us At:<br />
                                <a href="tel:330-349-5240">330-349-5240</a><br />
                                Billing/Insurance Questions:<br />
                                <a href="tel:330-907-1472">330-907-1472</a><br /><br />
                                    
                            </address>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card  border-0 bg-light text-black clear-card">


                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card  border-0 bg-light text-black clear-card">


                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card  border-0 bg-light text-black clear-card">


                        </div>
                    </div>
                </div>
            </fieldset>
        </div>




    </div>
    <!--
        <div class="col-3">
            <client-registeration></client-registeration>
        </div>
        -->
</div>